.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.calendar {
  font-family: "Satoshi","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px !important;
  border: none!important;
  box-shadow: 0px 10px 25px #6c757d26;
  border-radius: 10px!important;
  padding: 12px;
}

.react-datepicker__navigation {
  top: 20px !important;
  border-width: 0.8rem!important;
}
.react-datepicker__triangle { display: none!important;}
.react-datepicker__header {
  background-color: #FFFFFF!important;
  border-bottom: none!important;
  border-radius: 10px!important;
}
.react-datepicker__current-month {
  font-size: 16px !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  border-radius: 50%!important;
  background-color: #4a90e2!important;
}
.react-datepicker__current-month,
.react-datepicker__day-name {
  color: #6c757d!important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  margin: 0.366rem!important;
  padding: 6px;
  width: 29px!important;
  height: 29px!important;


}
.react-datepicker__day:hover {
  border-radius: 50%!important;
  background-color: #9cc8fc!important;
  color: #FFFFFF!important;
}
@media screen and (min-width: 600px) {
  .calendar {
    padding: 16px;
    font-size: 16px !important;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 34px!important;
    height: 34px!important;
    padding: 8px;
  }
  .react-datepicker__navigation {
    top: 25px !important;
  }
}
